import React from 'react'

export default function SocietyAndMedicine() {
  return (
    <div><br/>
        <h3 style={{textAlign:'center', textDecoration: 'underline'}}>SOCIETY AND MEDICINE</h3>
        <h4 style={{textAlign:'center'}}>Past papers</h4>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Year by Year</h5>
        <div className='container'>
            <div style={styles.year}>2023</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1Wcg2qCDwkkZ4JfGh9VTamEWH8YF7wvn6&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2022</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1H1xMwpqmNFrVNpMw5KWoam-aHADWgQ1t&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1lapjh38STeDNZDZaLBPSsDVfmuIOmKBB&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2021</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1kUUYznUM6ilV7fqMBBMtE4vzuPmLr2_B&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2020</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1jU3nNVVaWZ78pMPL2uRggMg05THeS_AC&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            {/* <div style={styles.year}>2019</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
            <div style={styles.year}>2018</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=15Tt_t11nAEnKlJHItn5Uk0iqDlFaG1Gb&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            {/* <div style={styles.year}>2017</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2016</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2015</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
            <div style={styles.year}>2014</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1ZBETukmONIAtaUnFC7UOegPVFOgaYh2H&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1yxYONJ3ov-7IcfEZBoMH10BvAIf06LQG&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            {/* <div style={styles.year}>2013</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
            <div style={styles.year}>2012</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=15qqTHomGGe9jRJudTvngbQ4aF3PMWEWn&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1RgdZUpjVfw3OiXBxLUD0qVTElkKkCps2&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            {/* <div style={styles.year}>2011</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2010</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
        </div>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Topic by Topic</h5>
        <div className='container'>
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Carbohydrates</a></div> */}
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Vitamins</a></div> */}
        </div>
        <h4 style={{textAlign:'center'}}>Notes</h4>
        <h4 style={{textAlign:'center'}}>Topical Revision Questions</h4>
    </div>
  )
}


const styles = {
    test: {
        padding: 6,
        margin: 8,
        textAlign: 'center'
    },
    testText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(0, 162, 232)',
        color: 'white',
        borderRadius: 2
    },
    topicText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(255, 120, 155)',
    },
    year: {
        fontWeight: 'bolder', 
        fontSize: 25,
        textAlign: 'center'
    }
}
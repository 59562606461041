import React from 'react'

export default function ClinicalScience() {
  return (
    <div><br/>
        <h3 style={{textAlign:'center', textDecoration: 'underline'}}>CLINICAL SCIENCE</h3>
        <h4 style={{textAlign:'center'}}>Past papers</h4>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Year by Year</h5>
        <div className='container'>
            <div style={styles.year}>2023</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1l2H96O0gTytedf9miveafvBgPVES-pfT&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2022</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1_A73JqLVd2O-_e2x2zmr-7h0ab--rqfj&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1-O562RQZQCyCsnne515QzObLvfQWC5EJ&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1bhJONnF8CEDautzHLq8umrKMfKJzrxeL&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2021</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1cksd_rV50c56k-hFluGwWr6rV01YWC-V&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2020</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1ToVl5I86zRHtsvexAGfu1CM0GZlTlM0w&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            {/* <div style={styles.year}>2019</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
        </div>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Topic by Topic</h5>
        <div className='container'>
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Carbohydrates</a></div>
            <div style={styles.test}><a href='' style={styles.topicText}>Vitamins</a></div> */}
        </div>
        <h4 style={{textAlign:'center'}}>Notes</h4>
        <h4 style={{textAlign:'center'}}>Topical Revision Questions</h4>
    </div>
  )
}


const styles = {
    test: {
        padding: 6,
        margin: 8,
        textAlign: 'center'
    },
    testText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(0, 162, 232)',
        color: 'white',
        borderRadius: 2
    },
    topicText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(255, 120, 155)',
    },
    year: {
        fontWeight: 'bolder', 
        fontSize: 25,
        textAlign: 'center'
    }
}
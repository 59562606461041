import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const STLink = styled(NavLink)`
    text-decoration: none;
    border: 1px dotted black;
    border-radius: 8px;
    padding: 8px;
    margin: 8px;
    font-weight: bolder;
    color: blue;
    font-size: 20px;

    &:hover {
        background: black;
        color: rgb(255,255,255);
        border-right: 3px solid grey;
        border-left: 3px solid grey;
    }
    
`

export default function Med(){
    
    return (
        
        <div><br/>
            <h1 style={styles.h1}>MED-2 PAGE</h1>
            <div className='body'>
                <p style={styles.p}>"I never talk about other people, just myself and my neighbours ... Guess the lecturer"</p>
                    <div><hr/>
                        <p style={styles.li}><STLink to="/tutorials/histo">Histology</STLink></p>
                        <p style={styles.li}><STLink to="/tutorials/embry">Embryology</STLink></p>
                        <p style={styles.li}><STLink to="/tutorials/gross">Gross Anatomy</STLink></p><hr/>
                        <p style={styles.li}><STLink to="/tutorials/bioch">Biochemistry</STLink></p><hr/>
                        <p style={styles.li}><STLink to="/tutorials/physi">Physiology</STLink></p><hr/>
                        <p style={styles.li}><STLink to="/tutorials/clini">Clinical Science</STLink></p><hr/>
                        <p style={styles.li}><STLink to="/tutorials/labsc">Lab Science</STLink></p><hr/>
                        <p style={styles.li}><STLink to="/tutorials/publi">Public Health</STLink></p><hr/>
                        <p style={styles.li}><STLink to="/tutorials/thera">Theraputics</STLink></p><hr/>
                        <p style={styles.li}><STLink to="/tutorials/socie">Society and Medicine</STLink></p><hr/>
                        <p style={styles.li}><STLink to="/tutorials/patho">Pathology</STLink></p><hr/>
                    </div>
            </div>
        </div>
    )
}

const styles = ({
    p: {
        fontFamily: 'arial',
        textAlign: 'center',
        marginTop: 14,
        marginBottom: 14,
        listStyle: 'none',
        
    },
    li: {
        fontFamily: 'arial',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
        
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'rgb(16, 167, 200)',
        fontWeight: 900,
    }
})
import React from 'react'

export default function Theraputics() {
  return (
    <div><br/>
        <h3 style={{textAlign:'center', textDecoration: 'underline'}}>THERAPEUTICS</h3>
        <h4 style={{textAlign:'center'}}>Past papers</h4>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Year by Year</h5>
        <div className='container'>
            <div style={styles.year}>2023</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1l7SRVfP3XOpX1EaLuadRd-HPcC_vEnw8&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2022</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1KBLQ0gWNHOhtBDozeUT7afalE-e4ImLP&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1r5YJ5ma2X91tp9OZPqi0SyyYxE-IVeYf&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1HCC0MtdazIESJ4skcGg1AiTCChFYL7SF&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1t1NK2E1ZGOmc2Iop8hEaZJfWuMUk2P5d&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2021</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=12SgCcjj7G5Vvor0yR_Zxb5Fujgu19cxX&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1RssXtMOek3SxI-kN89Yx8LUPct995Ecj&export=download' style={styles.testText}>Test 1 With Answers</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1c88S6leMmGGwX8xH54V8xQ6lbGanViwk&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1Iid7KPnOriGL8YtL9vlM4PrYgu4HV-VS&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1VA2IlDAnQMN07Z7lqXTtxNzas3Zphd2W&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2020</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1ksGRiwEWCzidCNjSuCj8Qxj_cej9tU0N&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1lIkSKj1rEYpI4nOVzSI4GWSDpbVTz_j3&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2019</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2018</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=12n8qzeLRgWzQgXIjUo46s16aY6Qzj0_2&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2017</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1t71jXyG0jmr1BmAQK80zR6rEbxfnQEQ7&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1c6I4I5f71hSOSZJck_p4OAPoL3BP_J1_&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2016</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1-yQ865cZA5k0qU2xLRXzl5bUV_6Sxx0b&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2015</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1z33fryKDd11iX56r5wkWgIufa30BBlc_&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2014</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1Ez7skNIeHYAh3EbW7Ow9BZCG9RvZmxE9&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
        </div>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Topic by Topic</h5>
        <div className='container'>
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Carbohydrates</a></div> */}
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Vitamins</a></div> */}
        </div>
        <h4 style={{textAlign:'center'}}>Notes</h4>
        <h4 style={{textAlign:'center'}}>Topical Revision Questions</h4>
    </div>
  )
}


const styles = {
    test: {
        padding: 6,
        margin: 8,
        textAlign: 'center'
    },
    testText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(0, 162, 232)',
        color: 'white',
        borderRadius: 2
    },
    topicText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(255, 120, 155)',
    },
    year: {
        fontWeight: 'bolder', 
        fontSize: 25,
        textAlign: 'center'
    }
}
import {Helmet} from 'react-helmet-async';

import React from 'react';
import logo from "../media/logo.png";
import Micheal from "../media/my.jpg";
import Micheal2 from "../media/star.jpg";
import Micheal3 from "../media/star.png";



export default function About(){
    return (
        <div>
            <Helmet>
                <title>STudy STar: About Page</title>
                <meta 
                    name='description' 
                    content="About Us"
                    href="/about"
                />
                <link rel="canonical" href='/about'/>
            </Helmet>
            <br/>
            <h1 style={styles.h1}>ABOUT PAGE</h1>
            <div className='body' style={{marginLeft: "4%", marginRight: "4%"}}>
                <h3 style={styles.titles}>What is STudy STar?</h3>
                <p style={styles.p}>
                    <b>STudy STar</b> is a web app that is dedicated to helping students become better in every walk of their school life. The 
                    founder and CEO of has understood how to manage hormones effectively, prepare you for the exam and give personal advice if some is 
                    required. Any questions can also be asked through Facebook by clicking 
                    <a href='https://www.facebook.com/studystarofficial' style={styles.href}> here</a>
                </p>
                <h3 style={styles.titles}>Why was STudy STar develped?</h3>
                <p style={styles.p}>
                    <b>STudy STar</b> was created to help you walk out of the exam room with a smile on your face.
                </p>
                <h3 style={styles.titles}>Who was STudy STar created for?</h3>
                <p style={styles.p}>
                    It was developed for:
                    <li>Senior Secondary School Student</li>
                    <li>1 <sup>st</sup> Year NQ Students</li>
                    <li>2 <sup>nd</sup> Year Med Students</li>
                </p>
                <h3 style={styles.titles}>Who developed STudy STar?</h3>
                <p style={styles.p}>
                    <b>STudy STar</b> was developed by <b>Mubanga Micheal</b>.
                    You can follow, like and message <b>Dr. Micheal STar</b> on <a href="https://www.instagram.com/michealstarx/" style={styles.href}>instagram</a> or <a href="https://www.facebook.com/michealstarofficial" style={styles.href}>Facebook.</a>
                    You can also view his personal website by <a href="https://michealstar.onrender.com"><b>clicking here</b></a>
                    <p style={{textAlign: 'center'}}><a href={Micheal3}><img src={Micheal3} alt="Micheal Mubanga" height={236} width={316}/></a></p>
                    {/* <p style={{textAlign: 'center'}}><a href={Micheal}><img src={Micheal} alt="Micheal Mubanga" height={316} width={316}/></a></p>
                    <p style={{textAlign: 'center'}}><a href={Micheal2}><img src={Micheal2} alt="Micheal Mubanga" height={316} width={316}/></a></p> */}
                </p>
                <h3 style={styles.titles}>Motto</h3>
                <p style={styles.p}>
                    Think beyond the clouds.
                </p>
                <h3 style={styles.titles}>Aim</h3>
                <p style={styles.p}>
                    To help develop Zambia by increasing the number of educated people.
                </p>
                <h3 style={styles.titles}>Logo</h3>
                <div style={{textAlign: 'center'}}><img src={logo} height={150} width={150} style={{borderRadius: 70}} alt="STudy STar Logo"/></div>
                <h3 style={styles.titles}>WhatsApp only</h3>
                <p style={styles.p}>+2609761781385</p>
                <h3 style={styles.titles}>Email Us</h3>
                <p style={styles.p}>
                    michealstarx@gmail.com
                </p>
            </div>
        </div>
    )
}

const styles = ({
    titles: {
        textAlign: 'center',
        fontFamily: 'impact'
    }, 
    p: {
        fontFamily: 'arial', 
        marginBottom: 15,
        fontSize: 18,
        textIndent: 8
    },
    img: {
        textAlign: 'center'
    },
    href: {
        textDecoration: 'none',
        fontWeight: 'bolder'
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'rgb(12, 143, 100)',
        fontWeight: 900
    }
})